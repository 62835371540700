.markdown {

    h1,
    h2,
    h3,
    h4 {
        letter-spacing: 0.1rem;
        text-transform: capitalize;
        line-height: 1.25;
        margin-bottom: 0.75rem;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 0.875rem;
    }

    p {
        margin-bottom: 1.25rem;
        color: hsl(209, 34%, 30%);
    }

    .section {
        width: 90vw;
        margin: 0 auto;
        max-width: 1170px;
    }

    @media screen and (min-width: 992px) {
        .section {
            width: 95vw;
        }
    }

    .markdown {
        padding: 4rem;
        display: grid;
        row-gap: 4rem;
    }

    .input {
        border-color: transparent;
        border-radius: 0.25rem;
        font-size: 1.2rem;
        line-height: 2;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        min-height: 30vh;
    }

    .input,
    .result {
        padding: 1rem 2rem;
    }

    @media screen and (min-width: 992px) {
        .markdown {
            min-height: 100vh;
            grid-template-columns: 1fr 1fr;
            column-gap: 4rem;
        }
    }

    /* unique styles */
    img {
        width: 100%;
        object-fit: cover;
        border-radius: 25px;
    }

    blockquote {
        font-size: 2rem;
        font-style: italic;
        border-left: 5px solid hsl(210, 22%, 49%);
        color: hsl(210, 22%, 49%);
        padding-left: 2rem;
        line-height: 2;
        margin-bottom: 1.5rem;
    }

    blockquote ::first-letter {
        text-transform: uppercase;
    }

    pre {
        background: #222;
        display: inline-block;
        border-radius: 0.25rem;
        padding: 1rem 2rem;
        color: yellow;
        margin-bottom: 1.5rem;
    }
}